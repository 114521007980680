import { Link } from 'react-router-dom';
import TablerIcon from '@components/TablerIcon';
import { IconBolt } from '@tabler/icons-react';
import { useCurrentQuota } from '@hooks/account';
import { useUser } from '../../../hooks/auth';

export default function AccountDetailsPage() {
  const { user } = useUser();
  const { quota } = useCurrentQuota();

  return (
    <div>
      <h1 className="h3 mb-5">Profil</h1>
      <div className="mb-4">
        <label htmlFor="email">Email:</label>
        <input disabled="disabled" type="text" className="form-control" id="email" value={user.email} />
      </div>
      <div className="mb-4">
        <div className="quota mb-4">
          <h6>Pozostało do wykorzystania:</h6>
          <ul>
            <li>
              <b>{quota?.pdfs}</b>
              {' '}
              plików PDF do załadowania
            </li>
            <li>
              <b>{quota?.questions}</b>
              {' '}
              pytań do wszystkich dokumentów
            </li>
            <li>
              Maksymalny rozmiar PDF:
              {' '}
              <b>{quota?.pdf_size}</b>
              {' '}
              MB/pdf
            </li>
          </ul>
        </div>
        <Link to="/pricing" className="btn btn-warning">
          <TablerIcon icon={IconBolt} stroke={1.5} size={30} />
          {' '}
          Dokup pakiet
        </Link>
      </div>
    </div>
  );
}
