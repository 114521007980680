import { useState } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { useNavigate } from 'react-router';
import {
  IconCloudUpload, IconHourglassHigh, IconSchool, IconUsersGroup, IconZoomQuestion,
  IconBrandHipchat, IconHistory, IconFileSearch, IconBook,
} from '@tabler/icons-react';
import { useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import Dropzone from '../components/Dropzone';
import SpinnerGrow from '../components/SpinnerGrow';
import TablerIcon from '../components/TablerIcon';
import { useLoginRegister } from '../hooks/templates';
import { useUser } from '../hooks/auth';
import { MotionItem, MotionViewport } from '../components/animations';
import { useCurrentQuota } from '../hooks/account';
import { uploadFile } from '../api/index';
import { useSettings } from '../hooks';
import BasePage from './layouts/BasePage';

const onUpload = ({
  files,
  setProgress,
  setIsSuccessUpload,
  resetDropzone,
  setProcessing,
  quota,
  queryClient,
  isAuthenticated,
  open,
  navigate,
}) => {
  if (!isAuthenticated) {
    return open();
  }

  if (quota == null) {
    resetDropzone();
    return toast.warning('Brak wykupionego pakietu.');
  }
  if (quota?.pdfs <= 0) {
    resetDropzone();
    return toast.warning('Wykorzystałeś swój limit wgranych plików. Dokup pakiet aby korzystać dalej.');
  }

  uploadFile('user/chat', files[0], {
    onUploadProgress: (e) => {
      const perc = (e.loaded / e.total) * 100;
      setProgress(perc);
      if (perc >= 100) setProcessing(true);
    },
  }).then((res) => {
    setIsSuccessUpload(true);
    if (res.data && !res.data?.errors) {
      const { chatRoom } = res.data;
      queryClient.invalidateQueries('user.quota');

      return navigate(`/playground/${chatRoom.uuid}`);
    }

    toast.error(res.data?.message);
    resetDropzone();
  }).catch((err) => {
    if (err.response.status === 422 || err.response.status === 400) {
      toast.warning(err.response?.data?.message);
    } else {
      toast.error(err.message);
    }
    resetDropzone();
  }).finally(() => {
    setProcessing(false);
  });
};

const onError = () => {
  toast.error('Dokument odrzucony.');
};

export default function HomePage() {
  const { settings } = useSettings();
  const [isProcessing, setProcessing] = useState(false);
  const {
    open, LoginRegisterModel,
  } = useLoginRegister();
  const queryClient = useQueryClient();

  const { isAuthenticated } = useUser();
  const navigate = useNavigate();
  const { quota } = useCurrentQuota(isAuthenticated);

  return (
    <BasePage>
      <section className="container-fluid jumbotron">
        <div className="container py-5">
          <MotionViewport>
            <div className="row flex-column align-items-center justify-content-center">
              <div className="col-md-7 col-sm-12  text-center mb-5 top-side">
                <MotionItem>
                  <h1 className="mb-3">
                    Porozmawiaj ze swoim
                    <span className="text-gradient-primary"> dokumentem</span>
                    , korzystając z możliwości AI.
                  </h1>
                  <p>
                    Zadaj pytanie dotyczące Twojego dokumentu,
                    a sztuczna inteligencja oparta o
                    {' '}
                    <b>GPT-4</b>
                    {' '}
                    go przeanalizuje i udzieli Ci odpowiedzi.
                  </p>
                </MotionItem>
              </div>
              <div className="col-md-6 col-sm-12">
                <MotionItem>
                  <div className="bottom-side">
                    <div className="w-100">
                      <Dropzone
                        onUpload={onUpload}
                        onError={onError}
                        name="pdf-file"
                        extraOnUploadProps={{
                          setProcessing,
                          quota,
                          queryClient,
                          isAuthenticated,
                          open,
                          navigate,
                        }}
                        dropzoneOptions={{
                          accept: { 'application/pdf': ['.pdf'] },
                          maxSize: 50 * 1024 * 1024, // (in bytes) 50 MB
                        }}
                      >
                        {({ getInputProps }) => (
                          isProcessing ? (
                            <div className="text-center">
                              <b>
                                <SpinnerGrow size="sm" />
                                {' '}
                                Ładowanie...
                              </b>
                            </div>
                          ) : (
                            <div className="text-center">
                              <b>
                                <TablerIcon icon={IconCloudUpload} size={40} />
                                <br />
                                {' '}
                                Załaduj swój plik PDF
                              </b>
                              <p>(przeciągnij i upuść)</p>
                              <input {...getInputProps()} />
                            </div>
                          )
                        )}
                      </Dropzone>
                    </div>
                  </div>
                </MotionItem>
              </div>
            </div>
          </MotionViewport>
        </div>
      </section>
      <section className="container-fluid section blue-section">
        <div className="container py-5 text-center">
          <MotionViewport>
            <MotionItem>
              <h2 className="section-title">Co zawiera?</h2>
              <span className="section-sub-title">
                {settings?.SITE_NAME}
                {' '}
                obejmuje następujące funkcje
              </span>
            </MotionItem>

            <div className="row align-items-stretch">
              <div className="col">
                <MotionItem>
                  <div className="feature">
                    <div className="feature-header">
                      <TablerIcon icon={IconBrandHipchat} size={40} />
                      {' '}
                      Pytaj o treść dokumentu
                    </div>
                    <div className="feature-body">
                      Dodaj swój dokument, a następnie zacznij z nim interakcję.
                    </div>
                  </div>
                </MotionItem>
              </div>
              <div className="col">
                <MotionItem>
                  <div className="feature">
                    <div className="feature-header">
                      <TablerIcon icon={IconZoomQuestion} size={40} />
                      {' '}
                      Szybka analiza pliku PDF
                    </div>
                    <div className="feature-body">
                      Dodaj swój plik PDF i zacznij zadawać pytania od razu.
                    </div>
                  </div>
                </MotionItem>
              </div>
              <div className="col">
                <MotionItem>
                  <div className="feature">
                    <div className="feature-header">
                      <TablerIcon icon={IconHistory} size={40} />
                      {' '}
                      Historia rozmowy
                    </div>
                    <div className="feature-body">
                      {settings?.SITE_NAME}
                      {' '}
                      zapamiętuje historię rozmowy i zawsze możesz do niej wrócić.
                    </div>
                  </div>
                </MotionItem>
              </div>
              <div className="col">
                <MotionItem>
                  <div className="feature">
                    <div className="feature-header">
                      <TablerIcon icon={IconFileSearch} size={40} />
                      {' '}
                      Streszczenia
                    </div>
                    <div className="feature-body">
                      Streszczaj swoje dokumenty w szybki sposób.
                    </div>
                  </div>
                </MotionItem>
              </div>
            </div>
          </MotionViewport>
        </div>
      </section>

      <section className="container-fluid section">
        <div className="container py-5 text-center">
          <MotionViewport>
            <h2 className="section-title">Dla kogo?</h2>
            <span className="section-sub-title">
              {settings?.SITE_NAME}
              {' '}
              to przydatne narzędzie dla każdego poszukującego szybkiej analizy dokumentów.
            </span>

            <div className="row align-items-stretch">
              <div className="col">
                <MotionItem>
                  <div className="feature">
                    <div className="feature-header">
                      <TablerIcon icon={IconSchool} size={40} />
                      {' '}
                      Studenci
                    </div>
                    <div className="feature-body">
                      Pomaga studentom skutecznie analizować materiały, szybko wyszukując informacje zawarte
                      w pliku.
                    </div>
                  </div>
                </MotionItem>
              </div>
              <div className="col">
                <MotionItem>
                  <div className="feature">
                    <div className="feature-header">
                      <TablerIcon icon={IconBook} size={40} />
                      {' '}
                      Badacze
                    </div>
                    <div className="feature-body">
                      Efektywniejsza analiza badań i artykułów. Sztuczna inteligencja wyciągnie kluczowe
                      informacje.
                    </div>
                  </div>
                </MotionItem>
              </div>
              <div className="col">
                <MotionItem>
                  <div className="feature">
                    <div className="feature-header">
                      <TablerIcon icon={IconUsersGroup} size={40} />
                      {' '}
                      Pracownicy biurowi
                    </div>
                    <div className="feature-body">
                      {settings?.SITE_NAME}
                      {' '}
                      przyspiesza pracę, pomijając etap czasochłonego czytania długich dokumentacji.
                    </div>
                  </div>
                </MotionItem>
              </div>
              <div className="col">
                <MotionItem>
                  <div className="feature">
                    <div className="feature-header">
                      <TablerIcon icon={IconHourglassHigh} size={40} />
                      {' '}
                      Osoby ceniące efektywność
                    </div>
                    <div className="feature-body">
                      {settings?.SITE_NAME}
                      {' '}
                      jest świetne dla osób, które nie mają czasu przeglądać stron treści w poszukiwaniu
                      informacji.
                    </div>
                  </div>
                </MotionItem>
              </div>
            </div>
          </MotionViewport>
        </div>
      </section>

      <LoginRegisterModel
        title="Zaloguj się aby kontynuować"
        onLoginRedirectTo="/playground"
      />
    </BasePage>
  );
}
