import { Link, Outlet } from 'react-router-dom';
import {
  IconInfoCircleFilled, IconLock, IconUser, IconFileDollar,
} from '@tabler/icons-react';
import Avatar from '@components/Avatar';
import AvatarPalceholder from '../../components/AvatarPalceholder';
import { useUser } from '../../hooks/auth';
import TablerIcon from '../../components/TablerIcon';

export default function AccountSettingsLayout() {
  const { user } = useUser();

  return (
    <section className="container py-5">
      <div className="alert alert-info">
        <TablerIcon icon={IconInfoCircleFilled} />
        {' '}
        Swoje dokumenty i rozmowy możesz zobaczyć tutaj:
        {' '}
        <Link to="/playground"><b>Dokumenty</b></Link>
        .
      </div>

      <div className="row my-5 gap-5">
        <div className="col-md-3 col-12">
          <div className="list-group rounded">
            <div className="list-group-item py-4 d-flex justify-content-center align-items-center flex-column" aria-current="true">
              { user.avatar ? <Avatar src={user.avatar} size={100} /> : <AvatarPalceholder username={user.username} size={100} />}
              <b className="my-2">{user.username.slice(0, 1).toUpperCase() + user.username.slice(1)}</b>
            </div>
            <Link to="" className="list-group-item list-group-item-action py-4">
              <TablerIcon icon={IconUser} stroke={1.25} />
              {' '}
              Profil
            </Link>
            <Link to="invoices" className="list-group-item list-group-item-action py-4">
              <TablerIcon icon={IconFileDollar} stroke={1.25} />
              {' '}
              Zamówienia
            </Link>
            <Link to="change-password" className="list-group-item list-group-item-action py-4">
              <TablerIcon icon={IconLock} stroke={1.25} />
              {' '}
              Zmiana hasła
            </Link>
          </div>
        </div>
        <div className="col border rounded p-5 mx-3 overflow-auto">
          <Outlet />
        </div>
      </div>
    </section>
  );
}
