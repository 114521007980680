import payuBar from '@images/payu/PayU.png';

export default function PayUMethods({ ...rest }) {
  const imageStyle = {
    maxWidth: '900px',
    width: '100%',
    height: 'auto',
  };

  return <img src={payuBar} alt="PayU" {...rest} style={imageStyle} />;
}
