import { memo, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useUser } from '@hooks/auth';
import LoginForm from './LoginForm';
import RegisterForm from './RegisterForm';
import ForgotPasswordForm from './ForgotPasswordForm';

export default memo(({
  onLogin,
  onRegister,
  onLoginRedirectTo,
  onRegisterRedirectTo,
}) => {
  const [currentForm, setCurrentForm] = useState('LOGIN'); // LOGIN | REGISTER | FORGOT_PASSWORD

  const navigate = useNavigate();
  const { isAuthenticated } = useUser();

  useEffect(() => {
    if (isAuthenticated) {
      if (typeof onLogin === 'function') return onLogin();
      if (onLoginRedirectTo) return navigate(onLoginRedirectTo);
    }
  }, [isAuthenticated]);

  return (
    <>
      {currentForm !== 'FORGOT_PASSWORD' && (
        <div className="text-center mb-4">
          <div className="btn-group btn-group-sm">
            <button type="button" className={['btn btn-primary', currentForm === 'REGISTER' && 'active'].join(' ')} onClick={() => setCurrentForm('LOGIN')}>Logowanie</button>
            <button type="button" className={['btn btn-primary', currentForm === 'LOGIN' && 'active'].join(' ')} onClick={() => setCurrentForm('REGISTER')}>Rejestracja</button>
          </div>
        </div>
      )}

      { currentForm === 'LOGIN' ? (
        <>
          <LoginForm onLogin={onLogin} setCurrentForm={setCurrentForm} onLoginRedirectTo={onLoginRedirectTo} />
          <span>
            Nie masz jeszcze konta?
            {' '}
            <Link onClick={() => setCurrentForm('REGISTER')}>Zarejestruj się</Link>
          </span>
        </>
      ) : currentForm === 'REGISTER' ? (
        <>
          <RegisterForm onRegister={onRegister} setCurrentForm={setCurrentForm} onRegisterRedirectTo={onRegisterRedirectTo} />
          <span>
            Masz już konto?
            {' '}
            <Link onClick={() => setCurrentForm('LOGIN')}>Zaloguj się</Link>
          </span>
        </>
      ) : (
        <ForgotPasswordForm setCurrentForm={setCurrentForm} />
      )}
    </>
  );
});
