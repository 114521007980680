import { useFormik } from 'formik';
import { useNavigate, useSearchParams } from 'react-router-dom';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import SuperButton from '../components/SuperButton';
import Switch from '../components/Switch';
import BasePage from './layouts/BasePage';
import { toastFormikErrors, gtag } from '../utils';
import register from '../api/auth';
import PasswordInput from '../components/PasswordInput';

export default function RegisterPage() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const redirectTo = searchParams.get('to');

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      accept: false,
    },
    validationSchema: Yup.object({
      email: Yup.string().required('Email required.').email('Nieprawidłowy email.'),
      password: Yup.string().required('Password required.').min(6, 'Hasło musi mieć minimum 6 znaków.'),
      accept: Yup.boolean().required().oneOf([true], 'Musisz zaakceptować regulamin.'),
    }),
    onSubmit: (values) => {
      register(values).then(({ data }) => {
        if (data.error === false) {
          formik.resetForm();
          toast.success(data.message, {
            autoClose: 10000,
          });

          gtag('event', 'conversion_event_signup', {
            method: 'Standard',
          });
          if (redirectTo) return navigate(`/login?to=${redirectTo}`);
          return navigate('/login');
        }
        return toast.error(data.message);
      }).catch((error) => {
        toast.error(error.response.data.message);
      }).finally(() => {
        formik.setSubmitting(false);
      });
    },
  });

  return (
    <BasePage>
      <section className="container">
        <div className="row my-5 py-5">
          <div className="col-md-5 m-auto p-5">
            <h1 className="text-center">Rejestracja</h1>
            <span className="text-muted d-block text-center mb-5">Tworzenie nowego konta</span>
            <form onSubmit={formik.handleSubmit}>
              <div className="mb-3">
                <label htmlFor="email" className="form-label">Email:</label>
                <input type="email" id="email" className="form-control" {...formik.getFieldProps('email')} />
              </div>
              <div className="mb-3">
                <label htmlFor="password" className="form-label">Hasło:</label>

                <PasswordInput id="password" className="form-control" placeholder="+6 znaków" skipDemoMode {...formik.getFieldProps('password')} />
              </div>
              <div className="d-flex mb-3">
                <Switch onChange={(checked) => formik.setFieldValue('accept', checked)} name="accept" checked={formik.values.accept} size="small" className="mx-2 mt-1" />

                <label htmlFor="accept" className="form-label" onClick={() => formik.setFieldValue('accept', !formik.values.accept)}>Znam i akceptuję regulamin oraz politykę prywatności.</label>
              </div>

              <SuperButton type="submit" isLoading={formik.isSubmitting} disabled={!formik.values.accept || !formik.values.email || !formik.values.password || formik.isSubmitting} onClick={() => toastFormikErrors(formik.errors)} className="btn btn-primary btn-block my-4">Zarejestruj</SuperButton>
            </form>
          </div>
        </div>
      </section>
    </BasePage>
  );
}
