import LogoZapytaj from '@images/logo/zapytajpdf.webp';
import { Link } from 'react-router-dom';

export default function Logo({
  className = '', to = '/',
}) {
  return (
    <Link to={to} className={className}>
      <img alt="Logo" src={LogoZapytaj} style={{ maxWidth: '250px', maxHeight: '70px' }} />
    </Link>
  );
}
