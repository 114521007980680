import { Link } from 'react-router-dom';
import { usePages } from '../hooks';

export default function Footer() {
  const {
    pages,
  } = usePages();

  return (
    <footer className="container">
      {new Date().getUTCFullYear()}
      {' '}
      &copy; wszelkie prawa zastrzeżone.
      <div className="d-flex justify-content-center gap-3">
        {pages?.map((page, i) => <Link to={`/p/${page.slug}`} key={i}>{page.title}</Link>)}
        <Link to="/contact/">Kontakt</Link>
      </div>
    </footer>
  );
}
