import { Badge } from 'react-bootstrap';
import { useMemo } from 'react';
import SectionLoading from '../../components/SectionLoading';
import { useSubscriptions } from '../../hooks/admin';
import { datetimeFormat } from '../../utils';
import { useDashboardSettings } from '../../hooks';
import AdvancedDataTable from '../../components/AdvancedDataTable';

export function OrdersListPage() {
  const {
    isLoading, subscriptions,
  } = useSubscriptions();
  const { settings } = useDashboardSettings();
  const columns = useMemo(() => [
    {
      name: 'ID',
      selector: (subscription) => (subscription.order_id),
    },
    {
      name: 'ID Ext',
      selector: (subscription) => (subscription.gateway_order_id),
    },
    {
      name: 'User',
      selector: (subscription) => (
        <>
          <b>{subscription.user_username}</b>
          <br />
          { subscription.user_email }
        </>
      ),
    },
    {
      name: 'Plan',
      selector: (subscription) => (
        <>
          <b>{subscription.package_name}</b>
          <br />
          <span className="text-muted">
            {subscription.price}
            {' '}
            {settings.CURRENCY_SYMBOL}
          </span>
        </>
      ),
    },
    {
      name: 'Status',
      selector: (subscription) => (
        <>
          {subscription.status === 1 ? (
            <Badge pill bg="success">Active</Badge>
          ) : (subscription.status === 0 ? (
            <Badge pill bg="warning">Not paid</Badge>
          ) : (subscription.status === 2
                        && <Badge pill bg="danger">Canceled</Badge>
          )
          )}
        </>
      ),
    },
    {
      name: 'Created at',
      selector: (subscription) => datetimeFormat(subscription.created_at),
    },
  ], []);

  if (isLoading) {
    return <SectionLoading center />;
  }

  return (
    <>
      <h1 className="mb-3">Orders</h1>

      <div className="row">
        <div className="col-12">
          <section className="bg-light rounded p-4">
            <AdvancedDataTable
              columns={columns}
              data={subscriptions}
              searchFunction={(subscription, searchQuery) => [subscription.gateway_subscription_id, subscription.sub_id, subscription.user_email, subscription.user_username].includes(searchQuery)}
            />
          </section>
        </div>
      </div>
    </>
  );
}
