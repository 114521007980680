import { Link } from 'react-router-dom';
import { IconCircleCheck } from '@tabler/icons-react';
import SuperButton from './SuperButton';
import { usePackages, useSettings } from '../hooks';
import { useUser } from '../hooks/auth';
import { useLoginRegister } from '../hooks/templates';
import SectionLoading from './SectionLoading';
import TablerIcon from './TablerIcon';
import { MotionItem, MotionDiv } from './animations';

export default function PricingCards() {
  const { isLoading, settings } = useSettings();
  const {
    isLoading: isPackagesLoading, packages,
  } = usePackages();

  const { isAuthenticated } = useUser();

  const {
    open, close, LoginRegisterModel,
  } = useLoginRegister();

  if (isLoading || !Object.keys(settings).length || isPackagesLoading) {
    return <SectionLoading center />;
  }

  return (
    <MotionDiv className="pricing-section" key={packages}>

      {!packages?.length && (
        <span>Brak pakietów do kupienia!</span>
      )}
      {packages?.map((pack) => (
        <MotionItem
          className={pack.is_popular ? 'pricing-card popular' : 'pricing-card'}
          key={pack.id}
          variants={{
            hidden: {
              opacity: 0,
              transform: 'translateY(20px) scale(0.8)',
            },
            show: {
              opacity: 1,
              transform: 'translateY(0) scale(1)',
              transition: {
                duration: 0.6,
                ease: 'backOut',
              },
            },

          }}
        >

          <div className="pricing-title">{pack.name}</div>
          <div className="pricing">
            {pack.price}
            {' '}
            {settings?.CURRENCY_SYMBOL}
            <span className="small-text" />
          </div>
          <div className="text-center">{pack.description}</div>
          <div className="pricing-body">
            <div className="mt-2">
              {isAuthenticated ? (
                ((pack.is_free || pack.price === 0) ? (
                  <SuperButton className="btn btn-primary w-100" disabled>Otrzymano</SuperButton>
                ) : (
                  <Link to={`../checkout/${pack.id}`} className="btn btn-primary d-block">Kup</Link>
                ))
              ) : (
              // eslint-disable-next-line react/button-has-type
                <button onClick={() => open()} className="btn btn-primary w-100">
                  {pack.is_free || pack.price === 0 ? 'Zarejestruj się' : 'Kup'}
                </button>
              )}
            </div>
            <ul>
              <li>
                <TablerIcon icon={IconCircleCheck} className="text-success" />
                {' '}
                <b>{pack.pdfs ? pack.pdfs : 'Bez limitu'}</b>
                {' '}
                {(pack.pdfs < 5) ? ((pack.pdfs < 2) ? 'plik' : 'pliki') : 'plików'}
                {' '}
                PDF
              </li>
              <li>
                <TablerIcon icon={IconCircleCheck} className="text-success" />
                {' '}
                Maks. rozmiar PDF:
                {' '}
                <b>{pack.pdf_size ? `${pack.pdf_size} MB/pdf` : 'Bez limitu'}</b>
              </li>
              <li>
                <TablerIcon icon={IconCircleCheck} className="text-success" />
                {' '}
                <b>{pack.questions ? pack.questions : 'Bez limitu'}</b>
                {' '}
                {(pack.questions < 5) ? ((pack.questions < 2) ? 'pytanie' : 'pytania') : 'pytań'}
                {' '}
                do dokumentów
              </li>
              {pack.features?.split('\n')?.map((feature, i) => (
                <li key={i}>
                  <TablerIcon icon={IconCircleCheck} className="text-success" />
                  {' '}
                  {feature}
                </li>
              ))}
            </ul>
          </div>

        </MotionItem>
      ))}

      <LoginRegisterModel
        title="Zaloguj się aby kontynuować"
        onLogin={() => close()}
      />
    </MotionDiv>
  );
}
