import {
  faComment, faDollarSign, faGem, faUsers,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card } from 'react-bootstrap';
import { useDashboardSettings } from '../../hooks';
import SectionLoading from '../../components/SectionLoading';
import { useDashboardAnalytics } from '../../hooks/admin';
import { datetimeFormat } from '../../utils';

export default function AdminDashboardPage() {
  const { isLoading: isSettingLoading, settings } = useDashboardSettings();
  const { isLoading: isAnalyticsLoading, analytics } = useDashboardAnalytics();

  if (isSettingLoading || isAnalyticsLoading || !Object.keys(analytics).length) {
    return <SectionLoading center />;
  }

  return (
    <>
      <h1 className="mb-5">Dashboard</h1>

      <div className="row row-cols-1 row-cols-md-2 row-cols-xl-2 row-cols-xxl-3 g-3 mb-3">
        <div className="col">
          <div className="infobox bg-success text-light p-3 d-flex">
            <div className="d-flex justify-content-center align-items-center">
              <FontAwesomeIcon icon={faDollarSign} size="4x" className="infobox-icon" />
            </div>
            <div className="p-3 d-flex flex-column flex-grow-1">
              <span className="infobox-title h2">
                {analytics.total_revenue}
                {' '}
                {settings.CURRENCY_SYMBOL}
              </span>
              <span className="infobox-desc">Total Income</span>
            </div>
          </div>
        </div>
        <div className="col">
          <div className="infobox bg-primary text-light p-3 d-flex">
            <div className="d-flex justify-content-center align-items-center">
              <FontAwesomeIcon icon={faUsers} size="4x" className="infobox-icon" />
            </div>
            <div className="p-3 d-flex flex-column flex-grow-1">
              <span className="infobox-title h2">{analytics.customers_count}</span>
              <span className="infobox-desc">Total Customers</span>
            </div>
          </div>
        </div>
        <div className="col">
          <div className="infobox bg-info text-light p-3 d-flex">
            <div className="d-flex justify-content-center align-items-center">
              <FontAwesomeIcon icon={faComment} flip="horizontal" className="infobox-icon" />
            </div>
            <div className="p-3 d-flex flex-column flex-grow-1">
              <span className="infobox-title h2">{analytics.documents_count}</span>
              <span className="infobox-desc">Chat Rooms</span>
            </div>
          </div>
        </div>
        <div className="col">
          <div className="infobox bg-warning p-3 d-flex">
            <div className="d-flex justify-content-center align-items-center">
              <FontAwesomeIcon icon={faGem} size="4x" className="infobox-icon" />
            </div>
            <div className="p-3 d-flex flex-column flex-grow-1">
              <span className="infobox-title h2">{analytics.paid_orders_count}</span>
              <span className="infobox-desc">Total paid orders</span>
            </div>
          </div>
        </div>
        <div className="col">
          <div className="infobox bg-primary text-light p-3 d-flex">
            <div className="d-flex justify-content-center align-items-center">
              <FontAwesomeIcon icon={faGem} className="infobox-icon" />
            </div>
            <div className="p-3 d-flex flex-column flex-grow-1">
              <span className="infobox-title h2">{analytics.orders_count}</span>
              <span className="infobox-desc">Total orders</span>
            </div>
          </div>
        </div>
      </div>

      <div className="row mt-5">
        <div className="col">
          <Card className="mb-5">
            <Card.Header className="card-title">Recent Customers</Card.Header>
            <Card.Body className="overflow-auto">
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Username</th>
                    <th>Joined at</th>
                  </tr>
                </thead>
                <tbody>
                  {analytics.recent_customers.map((customer, i) => (
                    <tr key={i}>
                      <td>{customer.id}</td>
                      <td>
                        <span>{customer.username}</span>
                        <br />
                        <i className="text-muted">{customer.email}</i>
                      </td>
                      <td>{datetimeFormat(customer.created_at)}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </Card.Body>
          </Card>
        </div>
        <div className="col-md-7">
          <Card>
            <Card.Header className="card-title">Recent Orders</Card.Header>
            <Card.Body className="overflow-auto">
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th>#ID</th>
                    <th>Price</th>
                    <th>Status</th>
                    <th>Created at</th>
                  </tr>
                </thead>
                <tbody>
                  {analytics.recent_orders.map((order, i) => (
                    <tr key={i}>
                      <td>{order.id}</td>
                      <td>
                        {order.amount}
                        {' '}
                        {settings.CURRENCY_SYMBOL}
                      </td>
                      <td>{order.status === 1 ? 'OPŁACONE' : 'NOPE'}</td>
                      <td>{datetimeFormat(order.created_at)}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </Card.Body>
          </Card>
        </div>
      </div>
    </>
  );
}
