import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRightLong, faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import Confetti from 'react-confetti';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import BasePage from './layouts/BasePage';

export default function ThankYouPage() {
  const [confetti, runConfetti] = useState(false);
  const width = window.innerWidth;
  const height = window.innerHeight;

  useEffect(() => {
    runConfetti(true);
  }, []);

  return (
    <BasePage>
      <Confetti width={width} height={height} recycle={false} run={confetti} numberOfPieces={400} gravity={0.05} />
      <div className="d-flex justify-content-center align-items-center flex-column py-5 mt-5">
        <FontAwesomeIcon icon={faCircleCheck} className="text-success" style={{ fontSize: '10rem' }} />

        <h1 className="h1 text-success mt-5">Gratulacje</h1>
      </div>
      <section className="container mb-4 d-flex justify-content-center">
        <div className="mb-3 text-center w-lg-50">
          <p className="h3">Twoje zamówienie zostało potwierdzone.</p>

          <Link to="/playground" className="btn btn-outline-primary mt-2">
            Przejdź do dokumentów
            {' '}
            <FontAwesomeIcon icon={faArrowRightLong} />
          </Link>
        </div>
      </section>
    </BasePage>
  );
}
