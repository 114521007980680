import axiosApi from './axiosApi';

export async function updateAccountDetails(data) {
  const req = await axiosApi.post('/user/profile', data);
  return req.data;
}

export async function currentQuota() {
  const req = await axiosApi.get('/user/quota');
  return req.data;
}

export async function getUserOrders() {
  const req = await axiosApi.get('/user/orders');
  return req.data;
}

export async function updateUserPassword(data) {
  const req = await axiosApi.post('/user/update-password', data);
  return req.data;
}

export async function getUserChatRoomList() {
  const req = await axiosApi.get('/user/chat-list');
  return req.data;
}

export async function getChatRoom(uuid) {
  const req = await axiosApi.get(`/user/chat/${uuid}`);
  return req.data;
}

export async function sendPrompt(uuid, prompt) {
  const req = await axiosApi.post(`/user/chat/${uuid}`, { prompt }, {
    timeout: 300000, // 5 minutes
  });
  return req.data;
}

export async function deleteChatRoom(uuid) {
  return axiosApi.delete(`/user/chat/${uuid}/delete`);
}

export async function getChatDocument(uuid) {
  const req = await axiosApi.get(`/user/chat/${uuid}/document`, {
    responseType: 'blob',
  });
  return req.data;
}
